import { getRequest, postRequest, postRequestWithStream } from "./axiosClient";

export const getAllThreads = (query) => getRequest(`threads/getAllThreads`, query);
export const getThread = (userId = "") => getRequest(`threads/${userId}`);
export const updateThread = (threadId) => postRequest(`threads/${threadId}/updateThread`);
export const newThread = (data) => postRequest(`threads/newThread`, data);
export const getData = (dataKey) => getRequest(`threads/data/${dataKey}`);
export const updateData = (dataKey) => postRequest(`threads/data/${dataKey}`);
export const _getMessages = (threadId, query) => getRequest(`threads/${threadId}/messages/all`, query);
export const getAnalysisMessages = (threadId, query) => getRequest(`threads/${threadId}/analysis`, query);
export const analysis = (threadId, data) => postRequest(`threads/${threadId}/analysis`, data);
export const exportCSV = (threadId, query, option) => getRequest(`threads/${threadId}/messages/exports`, query, option);
export const sendMessage = (threadId, data) => postRequest(`threads/${threadId}/messages/send`, data);