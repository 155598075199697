import React, { useEffect, useState } from 'react'
import { Button, Modal, Progress, Typography } from 'antd';
import classNames from 'classnames';
import { GoShieldCheck } from 'react-icons/go';
import { TbReport } from "react-icons/tb";
import { useDispatch, useSelector } from 'react-redux';

import { setStorage } from '../../../helpers';
import { setTotal } from '../../../redux/message/messageSlice';

const { Title } = Typography;

const StepModal = ({
  open,
  setOpen,
}) => {

  const dispatch = useDispatch();
  
  const step = useSelector((state) => parseInt(state.message.total / 5));
  const siderCollapsed = useSelector((state) => state.app.siderCollapsed);

  // const [step, setStep] = useState(0);

  const tours = [{
    title: "Judgment Free Zone",
    description: "Abby is a safe space where every thought and emotion is met with understanding, empathy and zero judgment.",
    icon: <GoShieldCheck className='text-6xl' />
  },
  {
    title: "Abby Learns About You",
    description: "Abby will ask questions to understand the situation that's bothering you, including asking about your background to personalize her responses & analysis.",
    icon: <img src="/imgs/talk.png" alt="analyzing" className='w-12' />
  },
  {
    title: "Your Personalized Roadmap",
    description: "Abby will analyze your situation and build a customized plan for deeper discussion, designed to help you find clarity, feel better, and reach a breakthrough moment.",
    icon: <TbReport className='text-6xl' />
  },
  {
    title: "How it works",
    description: "Keep the conversation going until you reach the 'Roadmap' milestone (around 20 messages). By then, Abby will have enough insights to really understand your story and craft personalized strategies, advice, and deeper topics just for you.",
    img: <img src="/imgs/step.png" alt="works" className='h-60' />
  },
  ];

  useEffect(() => {
    open && dispatch(setTotal(0));
  }, [open]);

  return (
    <Modal centered classNames={{ wrapper: classNames(!siderCollapsed && "!ms-72"), content: "", mask: classNames(!siderCollapsed && "!ms-72") }} open={open} onOk={() => { }} onCancel={() => { }} footer={[]} closable={false} maskClosable={false}>
      <div className='text-center'>
        <Progress /* strokeLinecap="butt" */ percent={(step + 1) * 25} showInfo={false} size={["100%", 12]} strokeColor="#000" trailColor="#eee" />
        <div className={classNames("flex mt-4 items-stretch gap-4", tours[step]?.icon && "flex-col")}>
          {tours[step]?.icon && <div className="text-center">{tours[step]?.icon}</div>}
          {tours[step]?.img}
          <div className={classNames("flex-1 flex flex-col justify-around items-center", !tours[step]?.icon && "min-h-60")}>
            <Title level={3} className='!font-extrabold'>{tours[step]?.title}</Title>
            <p className='text-[16px] text-center m-0'>{tours[step]?.description}</p>
            <br />
            <Button className={classNames('px-12 font-bold', tours[step]?.img && "mr-9")} size='large' type='primary' onClick={() => {
              if (step == tours.length - 1) {
                setStorage('registered');
                dispatch(setTotal(0));
                return setOpen(false);
              }
              dispatch(setTotal((step + 1) * 5));
            }}>{step == tours.length - 1 ? 'Get Started' : 'Next'}</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default StepModal;