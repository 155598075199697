import React from 'react';
import { Button, Modal, Progress } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { ExclamationCircleFilled } from '@ant-design/icons';
import { setOpenPayModal } from '../../../../redux/thread/threadSlice';

function WelcomebackModal({
  open,
  onClose,
  to
}) {

  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user);

  const setOpenModal = () => {
    if (user.activeSubscription && user.activeSubscription.status != 'active' && user.activeSubscription.status != 'trialing') {
      Modal.confirm({
        title: 'Your last invoice payment was failed!',
        icon: <ExclamationCircleFilled />,
        content: 'Will you try to pay the invoice again?',
        okText: "Yes",
        cancelText: "No",
        onOk() {
          window.open(user.activeSubscription.hosted_invoice, '_blank');
          return new Promise((resolve, reject) => {
            setTimeout(resolve, 1000);
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
          console.log('Cancel');
          dispatch(setOpenPayModal(to));
        },
      });
    } else {
      // setOpenModal();
      dispatch(setOpenPayModal(to));
    }
  };

  return (
    <Modal centered classNames={{ content: "!bg-white md:mb-16", mask: "!blur-md", wrapper: "" }} width={600} open={open} onOk={() => { }} onCancel={() => { }} footer={[]} closable={false} maskClosable={false}>
      <div className='text-center text-black'>
        {/* <Progress strokeLinecap="butt" percent={60} showInfo={false} size={["100%", 12]} strokeColor="#1b0035" trailColor="#e2d0a1" /> */}
        <h1 className='mt-4 mb-2 font-extrabold text-center text-primary'>Welcome back!</h1>
        <p className='my-2 italic text-[15px] text-gray-500 px-3 leading-tight'>Free conversations with Abby expire after 24 hours</p>
        <p className='my-2 italic text-[15px] text-gray-500 px-3 leading-tight'>Start your free trial to build on your previous conversation, or start a new conversation for free.</p>
        <div className='mt-6'>
          <Button className='m-2 font-bold rounded-sm' type='primary' size='large' onClick={() => onClose()}>New Chat</Button>
          <Button className='m-2 font-bold rounded-sm' size='large' onClick={() => {
            // setShowExpiredModal(false);
            setOpenModal();
          }}>{user.pm_last_four ? 'Pro ($9.99/Month)' : '7 Day Free Trial'}</Button>
        </div>
      </div>
    </Modal>
  )
}

export default WelcomebackModal;