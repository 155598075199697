import React, { useEffect, useState } from 'react';
import { Button, Layout, Menu, Modal, Progress, Steps } from 'antd';
import classNames from 'classnames';
import $ from 'jquery';
import { isMobile } from 'react-device-detect';
import { BsFillChatRightTextFill } from 'react-icons/bs';
import { FaRegEdit, FaUser } from 'react-icons/fa';
import { GoCheckCircleFill } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useSound from 'use-sound';

import LimitModal from '../../pages/Home/Partials/LimitModal';
import WelcomebackModal from '../../pages/Home/Partials/WelcomebackModal';
import { ExclamationCircleFilled, MenuOutlined, SignalFilled, YoutubeFilled } from '@ant-design/icons';
import SafeModal from './SafeModal';
import StepModal from './StepModal';
import UserMenu from './UserMenu';
import boopSfx from '../../../assets/sounds/effect1.mp3';
import { useSocket } from '../../../context/socket';
import { getStorage } from '../../../helpers';
import { setSiderCollapsed } from '../../../redux/app/appSlice';
import { getUser } from '../../../redux/auth/authSlice';
import { getMessages, setGenerating, setHasMore, setMessages, setPage } from '../../../redux/message/messageSlice';
import { getThreads, setOpenPayModal, setSelectedThread, setShowExpiredModal } from '../../../redux/thread/threadSlice';

import { newThread, sendMessage } from '../../../services/threadAPI';

import "./SideBar.scss";

const { Sider } = Layout;

function SideBar() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const socket = useSocket();

  const { id } = useParams();

  const siderCollapsed = useSelector((state) => state.app.siderCollapsed);
  const user = useSelector((state) => state.auth.user);
  const isPayed = useSelector((state) => state.auth.user.activeSubscription && (state.auth.user.activeSubscription.status == 'active' || state.auth.user.activeSubscription.status == 'trialing'));
  const selectedThread = useSelector((state) => state.thread.selectedThread);
  const threads = useSelector((state) => state.thread.threads);
  const thread = useSelector((state) => state.thread.threads.find(_thread => _thread._id === selectedThread));
  const showExpiredModal = useSelector((state) => state.thread.showExpiredModal);
  const messages = useSelector((state) => state.message.messages);
  const page = useSelector((state) => state.message.page);
  const limit = useSelector((state) => state.message.limit);
  const hasMore = useSelector((state) => state.message.hasMore);
  const loading = useSelector((state) => state.message.loading);
  const totalMessages = useSelector((state) => state.message.total);
  const step = useSelector((state) => parseInt(state.message.total / 5));
  // const [current, setCurrent] = useState("");
  const [openTour, setOpenTour] = useState(false);
  const [showAnalyzeModal, setShowAnalyzeModal] = useState(false);
  const [showWelcomebackModal, setShowWelcomebackModal] = useState(false);
  const [showSafeModal, setShowSafeModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("positive");
  const [analysisData, setAnalysisData] = useState(null);
  const [items, setItems] = useState([]);

  const [effectSound] = useSound(boopSfx);

  const onClick = (e) => {
    console.log('click ', e.key);
    /* if (current) {
      return notification.info({
        description: 'Please wait for the current analysis to finish.',
      });
    }
    setCurrent(e.key);
    // setOpenModal(`/data/${e.key}`);
    // navigate(`/data/${e.key}`);
    socket.emit('analysisMessage', { dataKey: e.key });
    if (isMobile) {
      dispatch(setSiderCollapsed(true));
    } */
  };

  const setOpenModal = (to) => {
    if (user.activeSubscription && (user.activeSubscription.status != 'active' && user.activeSubscription.status != 'trialing')) {
      Modal.confirm({
        title: 'Your last invoice payment was failed!',
        icon: <ExclamationCircleFilled />,
        content: 'Will you try to pay the invoice again?',
        okText: "Yes",
        cancelText: "No",
        onOk() {
          window.open(user.activeSubscription.hosted_invoice, '_blank');
          return new Promise((resolve) => {
            setTimeout(resolve, 1000);
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
          console.log('Cancel');
          dispatch(setOpenPayModal(to));
        },
      });
    } else if (user.activeSubscription && (user.activeSubscription.status == 'active' || user.activeSubscription.status === 'trialing')) {
      navigate(to);
    } else {
      dispatch(setOpenPayModal(to));
    }
  };

  const handleNewChat = () => {
    newThread().then(res => {
      // console.log(res.data);
      dispatch(setPage(1));
      dispatch(setHasMore(true));
      dispatch(setMessages([]));
      dispatch(getThreads());
      navigate(location.pathname.replace(selectedThread, res.data.thread._id));
      dispatch(setSelectedThread(res.data.thread._id));
    }).catch(err => console.log(err));
  };

  useEffect(() => {
    setShowSafeModal(!!getStorage('registered'));
    dispatch(getThreads());
  }, []);

  useEffect(() => {
    if (id && id !== selectedThread) {
      dispatch(setSelectedThread(id));
    } else if (!id && selectedThread) {
      if(location.pathname.includes('playground')) {
        navigate(selectedThread);
      }
    }
  }, [id, selectedThread]);
  /* 
    useEffect(() => {
      if (socket && current) {
        socket.on('_analysisMessage', (data) => {
          setCurrent("");
        });
      }
      return () => {
        if (socket) {
          socket.off('_analysisMessage');
        }
      };
    }, [socket, current]); */

  useEffect(() => {
    if (threads.length > 0) {
      setItems(threads.map((thread) => {
        if (thread.subTitles?.length > 0) {
          return {
            key: thread._id,
            label: <p className='m-0 text-[16px] font-bold max-w-full truncate'>{thread.title || "New chat"}</p>,
            icon: <BsFillChatRightTextFill />,
            children: thread.subTitles.map((subTitle, index) => {
              return {
                key: thread._id + index,
                icon: <span className='!text-black font-bold bg-white rounded-full w-[18px] h-[18px] flex items-center justify-center'>{index + 1}</span>,
                label: <p className='m-0 text-[14px] font-bold max-w-full truncate'>{subTitle.title || "New chat"}</p>,
                onClick: async () => {
                  console.log('----', subTitle.desc);
                  dispatch(setMessages([
                    {
                      role: 'assistant',
                      content: subTitle.desc,
                      animate: true,
                    },
                    ...messages,
                  ]));
                  await sendMessage(thread._id, { prompt: subTitle.desc });
                }
              };
            }),
          };
        }
        return {
          key: thread._id,
          label: <p className='m-0 text-[16px] font-bold'>{thread.title || "New chat"}</p>,
          icon: <BsFillChatRightTextFill />,
          children: [
            {
              key: thread._id + '0',
              className: 'stepper-view-menu',
              label: <>
                <Steps
                  direction="vertical"
                  size="small"
                  current={step}
                  className={classNames('h-full min-h-72 analysis-steps', `step-${step + 1} percent-${(totalMessages % 5) * 20}`)}
                  items={[
                    {
                      title: <span className="font-bold" /* ref={ref1} */>Listening ({step == 0 ? parseInt(totalMessages % 5) : 5}/5)</span>,
                    },
                    {
                      title: <span className="font-bold" /* ref={ref2} */>Analyzing ({step == 1 ? parseInt(totalMessages % 5) : (step > 1 ? 5 : 0)}/5)</span>,
                    },
                    {
                      title: <span className="font-bold" /* ref={ref3} */>Roadmap {/* ({step == 2 ? (totalMessages % 20) : (step > 2 ? 10 : 0)}/10) */}</span>,
                    },
                  ]}
                />
              </>
            },
          ]
        };
      }));
      if (!selectedThread) {
        dispatch(setSelectedThread(threads[0]._id));
      }
    }
  }, [threads, selectedThread, totalMessages, step, messages]);

  useEffect(() => {
    if (siderCollapsed) { 
      setItems([]);
    } else {
      setTimeout(() => {
        setItems(threads.map((thread) => {
          if (thread.subTitles?.length > 0) {
            return {
              key: thread._id,
              label: <p className='m-0 text-[16px] font-bold max-w-full truncate'>{thread.title || "New chat"}</p>,
              icon: <BsFillChatRightTextFill />,
              children: thread.subTitles.map((subTitle, index) => {
                return {
                  key: thread._id + index,
                  icon: <span className='!text-black font-bold bg-white rounded-full w-[18px] h-[18px] flex items-center justify-center'>{index + 1}</span>,
                  label: <p className='m-0 text-[14px] font-bold max-w-full truncate'>{subTitle.title || "New chat"}</p>,
                  onClick: async () => {
                    console.log('----', subTitle.desc);
                    dispatch(setMessages([
                      {
                        role: 'assistant',
                        content: subTitle.desc,
                        animate: true,
                      },
                      ...messages,
                    ]));
                    await sendMessage(thread._id, { prompt: subTitle.desc });
                  }
                };
              }),
            };
          }
          return {
            key: thread._id,
            label: <p className='m-0 text-[16px] font-bold'>{thread.title || "New chat"}</p>,
            icon: <BsFillChatRightTextFill />,
            children: [
              {
                key: thread._id + '0',
                className: 'stepper-view-menu',
                label: <>
                  <Steps
                    direction="vertical"
                    size="small"
                    current={step}
                    className={classNames('h-full min-h-72 analysis-steps', `step-${step + 1} percent-${(totalMessages % 5) * 20}`)}
                    items={[
                      {
                        title: <span className="font-bold" /* ref={ref1} */>Listening ({step == 0 ? parseInt(totalMessages % 5) : 5}/5)</span>,
                      },
                      {
                        title: <span className="font-bold" /* ref={ref2} */>Analyzing ({step == 1 ? parseInt(totalMessages % 5) : (step > 1 ? 5 : 0)}/5)</span>,
                      },
                      {
                        title: <span className="font-bold" /* ref={ref3} */>Roadmap {/* ({step == 2 ? (totalMessages % 20) : (step > 2 ? 10 : 0)}/10) */}</span>,
                      },
                    ]}
                  />
                </>
              },
            ]
          };
        }));
      }, [150]);
    }
  }, [siderCollapsed]);

  useEffect(() => {
    if (socket) {
      socket.on('answer', (data) => {
        // console.log(data);
        if (data.end) {
          dispatch(setGenerating(false));
        } else {
          if (selectedThread == data.threadId) {
            dispatch(setMessages([
              {
                role: 'assistant',
                content: data.content,
              },
              ...messages.slice(1)
            ]));
          }
        }
      });

      socket.on('limitReached', () => {
        dispatch(getThreads());
        if (isPayed) {
          dispatch(setShowExpiredModal(true));
        }
      });
    }
    return () => {
      if (socket) {
        socket.off('answer');
        socket.off('limitReached');
      }
    }
  }, [socket, messages, isPayed]);

  useEffect(() => {
    if (socket) {
      socket.on("analysisData", (data) => {
        effectSound();
        setAnalysisData(data);
        setShowAnalyzeModal(true);
      });
      socket.on("analyzed_thread", (data) => {
        // console.log("analyzed_thread", data);
        effectSound();
        dispatch(getThreads());
      });
      socket.on("SUBSCRIPTION_EVENT", (data) => {
        console.log('SUBSCRIPTION_EVENT', data);
        dispatch(getUser());
        dispatch(getThreads());
      });
    }

    return () => {
      if (socket) {
        socket.off("SUBSCRIPTION_EVENT");
        socket.off("analysisData");
        socket.off("analyzed_thread");
      }
    }
  }, [socket, effectSound]);

  useEffect(() => {
    // console.log(thread);
    if (thread && thread.back == 1 && !isPayed) {
      setShowWelcomebackModal(true);
    } else if (thread && thread.status == 1 && !isPayed) {
      dispatch(setShowExpiredModal(true));
    } else if (thread && isPayed) {
      dispatch(setShowExpiredModal(false));
      setShowWelcomebackModal(false);
    }
  }, [thread, isPayed]);

  useEffect(() => {
    if (!loading && selectedThread && hasMore) {
      console.log('loading...')
      dispatch(getMessages({ threadId: selectedThread, query: { page, limit } }));
    }
  }, [selectedThread, page]);

  return (
    <Sider
      style={{
        overflow: 'auto',
        height: '100%',
        position: 'fixed',
        insetInlineStart: 0,
        top: 0,
        bottom: 0,
        scrollbarWidth: 'thin',
        scrollbarColor: 'unset',
        zIndex: 999,
      }}
      breakpoint='md'
      onBreakpoint={(broken) => {
        if (broken) {
          dispatch(setSiderCollapsed(true));
        } else {
          dispatch(setSiderCollapsed(false));
        }
      }}
      className={classNames(!isPayed && "", 'sidebar !bg-transparent')}
      collapsedWidth={0}
      width={isMobile ? "100%" : 288}
      collapsed={siderCollapsed}
      collapsible
      trigger={null}
      onClick={(e) => {
        if ($(e.target).hasClass('ant-layout-sider-children')) {
          dispatch(setSiderCollapsed(true));
        }
      }}
    >
      <div className={classNames("inline-flex flex-col h-full bg-gray-100 w-72")}>
        <div className={classNames('h-16 flex items-center justify-between sticky top-0 z-[9999] bg-gray-100 shadow-sm', isMobile ? 'justify-between' : '')}>
          <Button icon={<MenuOutlined />} type='text' className="ml-2" onClick={() => {
            dispatch(setSiderCollapsed(!siderCollapsed));
          }} />
          {/* {isMobile && <div className='flex items-center gap-4 mr-2'>
            <Link to="/playground" className="text-gray-600" onClick={(e) => {
              e.preventDefault();
              dispatch(setSiderCollapsed(!siderCollapsed));
              navigate('/playground');
            }}>
              Text Chat
            </Link>
            <Link to="/voice-playground" className="text-gray-600" onClick={(e) => {
              e.preventDefault();
              dispatch(setSiderCollapsed(!siderCollapsed));
              navigate('/voice-playground');
            }}>
              Voice Chat
            </Link>
            <UserMenu />
          </div>} */}
        </div>
        <div className="flex-1">
          {(!isPayed && messages.length > 0) && <>
            {/* <Progress className='leading-[0] [&_.ant-progress-outer]:inline-flex' status="active" strokeLinecap="butt" percent={totalMessages / 40 * 100} showInfo={false} size={["100%", 10]} strokeColor="#1b0035" trailColor="#fff" /> */}

            <div className="gap-4 p-6 text-center min-h-96">
              {/* {config.steps.map((_step, index) => <div key={index} className={classNames("rounded-lg border border-gray-400 border-solid py-2 text-lg flex items-center justify-center gap-2 text-gray-400", step === index ? 'cursor-wait' : 'cursor-default')}>
                {step === index && <Spin size='small' />} <span className={classNames(step === index && "animate-shine")} style={{ animationDuration: "10s" }}>{_step}</span>
              </div>)} */}
              <Steps
                direction="vertical"
                size="small"
                current={step}
                className={classNames('h-full min-h-96 analysis-steps', `step-${step + 1} percent-${(totalMessages % 5) * 20}`)}
                items={[
                  {
                    title: <span className="font-bold" /* ref={ref1} */>Listening ({step == 0 ? parseInt(totalMessages % 5) : 5}/5)</span>,
                  },
                  {
                    title: <span className="font-bold" /* ref={ref2} */>Analyzing ({step == 1 ? parseInt(totalMessages % 5) : (step > 1 ? 5 : 0)}/5)</span>,
                  },
                  {
                    title: <span className="font-bold" /* ref={ref3} */>Roadmap {/* ({step == 2 ? (totalMessages % 20) : (step > 2 ? 10 : 0)}/10) */}</span>,
                  },
                ]}
              />
              {/* <div className='text-lg text-gray-500'>
                Abby will ask questions to learn about your specific situation.
              </div> */}
            </div>
          </>}
          {(!siderCollapsed && isPayed) && <>
            <Menu
              onClick={onClick}
              className='w-full sidebar-menu'
              openKeys={[selectedThread]}
              selectedKeys={[]}
              mode="inline"
              items={items}
              onOpenChange={(openKeys) => {
                if (openKeys[openKeys.length - 1]) {
                  dispatch(setPage(1));
                  dispatch(setHasMore(true));
                  dispatch(setMessages([]));
                  dispatch(setSelectedThread(openKeys[openKeys.length - 1]));
                  navigate(location.pathname.replace(openKeys[0], openKeys[1]));
                }
              }}
            />
            <div className='h-16 p-4 text-center'>
              <Button className='inline-flex items-center' icon={<FaRegEdit />} type='primary' onClick={handleNewChat}>New Chat</Button>
            </div>
          </>
          }
        </div>
        <div className='h-16 p-2 border-0 border-t border-gray-300 border-solid'>
          {/* {(user.activeSubscription?.status !== 'active' && user.activeSubscription?.status !== 'trialing') && <div className="py-4 text-center">
            <Button
              size='large'
              onClick={setOpenModal}
              className="w-4/5 font-semibold"
            >{user.pm_last_four ? 'Upgrade to PRO' : '7 Day Free Trial'}</Button>
          </div>} */}
          <UserMenu />
        </div>
      </div>
      <StepModal open={openTour} setOpen={setOpenTour} />
      <Modal centered classNames={{ content: "!bg-white" }} width={500} open={showAnalyzeModal} onOk={() => { }} onCancel={() => { }} footer={[]} closable={false} maskClosable={false}>
        <div className='text-center text-black'>
          <Progress strokeLinecap="butt" percent={40} showInfo={false} size={["100%", 12]} strokeColor="#1b0035" trailColor="#e2d0a1" />
          <h1 className='my-4 font-extrabold text-center text-primary'>Your First Analysis!</h1>
          <p className='p-3 my-4 text-[16px] text-gray-100 rounded-md bg-primary'><b>Abby:</b> I did a quick analysis on our conversation so far...</p>
          <div className='my-2 md:my-4'>
            <Button className={classNames('m-1 rounded-none', selectedTab != 'positive' && 'bg-[#eee]')} size='' onClick={() => setSelectedTab("positive")}>Positive Traits</Button>
            <Button className={classNames('m-1 rounded-none', selectedTab != 'areas_for_improvement' && 'bg-[#eee]')} size='' onClick={() => setSelectedTab("areas_for_improvement")}>Areas of Improvement</Button>
          </div>
          <div className="my-2 text-left md:my-4">
            {
              analysisData && analysisData[selectedTab].map((data, index) => <div key={index} className='flex items-center'>
                <GoCheckCircleFill className='w-14' fontSize={30} color={selectedTab == 'positive' ? '#27cf27' : '#DB9516'} />
                <p className='flex-1 my-2 md:my-4' dangerouslySetInnerHTML={{ __html: data.replace("**", '<b>').replace("**", '</b>') }}></p>
              </div>)
            }
          </div>
          <Button className='px-20 font-bold' type='primary' size='large' onClick={() => {
            setShowAnalyzeModal(false);
          }}>Keep Chatting</Button>
        </div>
      </Modal>
      <LimitModal open={showExpiredModal} onClose={() => setShowExpiredModal(false)} thread={thread} />
      <WelcomebackModal open={showWelcomebackModal} onClose={() => {
        handleNewChat();
        setShowWelcomebackModal(false);
      }} /* to="/playground" */ />
      <SafeModal open={showSafeModal} onClose={() => { 
        setShowSafeModal(false);
        setOpenTour(true);
      }} />
    </Sider>
  )
}

export default SideBar;