import React, { useEffect, useState } from 'react';
import { Button, Modal, Progress, Skeleton, Spin } from 'antd';
import classNames from 'classnames';
import { IoIosCheckmarkCircle, IoIosCheckmarkCircleOutline } from "react-icons/io";
import { TiStarFullOutline } from "react-icons/ti";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';

import { ExclamationCircleFilled } from '@ant-design/icons';
import MarkdownPreview from "@uiw/react-markdown-preview";
import { setOpenPayModal } from '../../../../redux/thread/threadSlice';

const LimitModal = ({
  open,
  onClose,
  thread,
}) => {

  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user);
  const siderCollapsed = useSelector((state) => state.app.siderCollapsed);
  const isPayed = useSelector((state) => state.auth.user.activeSubscription && (state.auth.user.activeSubscription.status == 'active' || state.auth.user.activeSubscription.status == 'trialing'));

  // const [selectedTab, setSelectedTab] = useState("");
  const tabs = ['step1', 'step2', 'step3'];
  const [step, setStep] = useState(0);

  const setOpenModal = () => {
    if (user.activeSubscription && user.activeSubscription.status != 'active' && user.activeSubscription.status != 'trialing') {
      Modal.confirm({
        title: 'Your last invoice payment was failed!',
        icon: <ExclamationCircleFilled />,
        content: 'Will you try to pay the invoice again?',
        okText: "Yes",
        cancelText: "No",
        onOk() {
          window.open(user.activeSubscription.hosted_invoice, '_blank');
          return new Promise((resolve, reject) => {
            setTimeout(resolve, 1000);
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
          console.log('Cancel');
          dispatch(setOpenPayModal('/playground'));
        },
      });
    } else {
      // setOpenModal();
      dispatch(setOpenPayModal('/playground'));
    }
  };

  /* useEffect(() => {
    if (thread && thread.analysis) {
      setSelectedTab(Object.keys(thread.analysis)[0]);
    }
  }, [thread]); */

  useEffect(() => {
    if (open) {
      setStep(5);
      setTimeout(() => {
        setStep(0);
      }, 2000);
    }
  }, [open]);

  return (
    <Modal centered classNames={{ content: classNames("!bg-white md:mb-16", step == 3 && "!pt-12 !px-10"), mask: classNames(!siderCollapsed && "!ms-72"), wrapper: classNames(!siderCollapsed && "!ms-72") }} width={600} open={open} onOk={() => { }} onCancel={() => { onClose(); }} footer={[]} closable={false} maskClosable={true}>
      {(step !== 5 && thread?.analysis?.[tabs[0]]) ? <div className='text-center text-black'>
        {step < 3 && <>
          <Progress /* strokeLinecap="butt" */ status='active' percent={(step + 1) * 33.3} showInfo={false} size={["100%", 12]} strokeColor="#000" trailColor="#eee" />
          <div className="mt-4 text-center">
            {/* <img src={`/imgs/step${step}.png`} alt="step1" className='w-20 h-20 rounded-full' /> */}
            <div className="w-20 h-20 rounded-full bg-black text-white inline-flex items-center justify-center">
              <div>
                <h2 className="font-bold m-0 leading-tight">{10 + step * 5}</h2>
                <h5 className="font-bold m-0 leading-tight">Mins</h5>
              </div>
            </div>
          </div>
        </>}
        {step < 3 && <>
          {thread?.analysis?.[tabs[0]] ? <PerfectScrollbar className='max-h-[calc(100vh_-_400px)] mt-4'>
            <div className={classNames('w-full text-left', step == 3 && 'bg-gray-100 p-4 rounded-lg')}>
              {/* {step == 4 && <h2 className='mt-4 mb-2 font-extrabold text-center'>Your 7 Day Program</h2>} */}
              {thread?.analysis && <MarkdownPreview
                className={classNames("!bg-transparent markdown-preview analysis [&_a]:hidden [&_h2]:text-center px-4 pb-4 rounded-lg")}
                source={thread?.analysis[tabs[step]]}
              />}
            </div>
          </PerfectScrollbar> : <div className='text-center'><Spin /> <p className='animate-pulse'>Analyzing...</p></div>}
        </>}
        {step == 3 && <div className='flex flex-wrap justify-between items-center'>
          <div className='w-full md:w-1/2'>
            <h2 className="text-center">
              Ready to Dive Deeper with Abby?
            </h2>
            <ul className='list-none text-left leading-8 px-2'>
              <li className='italic flex items-center gap-2'><IoIosCheckmarkCircle className='text-lg text-green-700' /> Unlimited Messages & New Chats</li>
              <li className='italic flex items-center gap-2'><IoIosCheckmarkCircle className='text-lg text-green-700' /> Advanced Voice Mode</li>
              <li className='italic flex items-center gap-2'><IoIosCheckmarkCircle className='text-lg text-green-700' /> Daily or Weekly Check-Ins</li>
            </ul>
            <Button type="primary" className='px-8 font-bold rounded-sm' size='large' onClick={() => {
              // setShowExpiredModal(false);
              setOpenModal();
            }}>{user.pm_last_four ? 'Pro ($9.99/Month)' : '7 Day Free Trial'}</Button>
            <p className='italic text-gray-600 mt-2 flex gap-1 items-center justify-center'><IoIosCheckmarkCircleOutline className='text-lg' /> {user.pm_last_four ? 'Cancel Anytime' : 'No payment due now'}.</p>
          </div>
          <div className="w-full md:w-1/2 md:pl-8">
            <div className='border border-solid border-gray-200 rounded-lg p-4 shadow'>
              <div className="flex items-center gap-2 mb-4">
                <img src="/imgs/emily.png" alt="emily" className='w-14 h-14 rounded-full shadow' />
                <div>
                  <h3 className='m-0'>Emily Clark</h3>
                  <div>
                    <TiStarFullOutline className='text-yellow-600 text-lg' />
                    <TiStarFullOutline className='text-yellow-600 text-lg' />
                    <TiStarFullOutline className='text-yellow-600 text-lg' />
                    <TiStarFullOutline className='text-yellow-600 text-lg' />
                    <TiStarFullOutline className='text-yellow-600 text-lg' />
                  </div>
                </div>
              </div>
              <p className='m-0'>
                "I've been using Abby for a few months now, and I can honestly say it's been a game-changer. There have been countless nights where I felt completely alone and overwhelmed, but Abby was there to guide me through the storm."
              </p>
            </div>
          </div>
        </div>}
        <div className=''>
          {step < 3 && <><Button type='primary' disabled={!thread?.analysis?.[tabs[0]]} className='px-10 font-bold rounded-sm' size='large' onClick={() => {
            if (isPayed && step == 2) {
              onClose();
            } else {
              setStep(step + 1);
            }
          }}>{(isPayed && step == 2) ? "Close" : "Next"}</Button></>}
        </div>
      </div> : <div className='text-center text-black px-10 mb-10'>
        <Progress /* strokeLinecap="butt" */ status='active' percent={10} showInfo={false} size={["100%", 12]} strokeColor="#000" trailColor="#eee" />
        <div className="my-4 text-center">
          <Spin size='large' />
        </div>
        <h2>Creating Your Discussion<br />Roadmap...</h2>
      </div>}
    </Modal>
  )
}

export default LimitModal;